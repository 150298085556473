<template>
  <el-dialog
    v-model="showDialog"
    width="960"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-mapping-edit-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-mapping-edit-dialog-edit-header__title">
        {{ t('title.accountMapping') }}
      </h4>
    </template>
    <div class="elv-account-mapping-edit-content">
      <div class="elv-account-mapping-edit-content-head">
        <template v-if="props.type === 'EXPORT'">
          <div class="elven-logo">
            <img src="@/assets/img/elven-logo.png" />
          </div>
          <div class="arrow-right-line">
            <SvgIcon name="arrow-right-line" width="16" height="16" fill="#838D95" />
          </div>
          <div class="xero-logo">
            <img src="@/assets/img/xero.png" />
          </div>
        </template>
        <template v-else>
          <div class="csv-file">
            <SvgIcon name="csv-file" width="24" height="24" /><span>{{
              props.importMappingData?.lastEntityFile?.name
            }}</span>
          </div>
          <div class="arrow-right-line">
            <SvgIcon name="arrow-right-line" width="16" height="16" fill="#838D95" />
          </div>
          <div class="elven-logo">
            <img src="@/assets/img/elven-logo.png" />
          </div>
        </template>
      </div>
      <div class="elv-account-mapping-edit-content-body">
        <el-table
          ref="tableListRef"
          v-loading="tableLoading"
          :data="tableData"
          border
          :show-header="props.type === 'IMPORT'"
          :height="tableHeight"
          row-key="code"
          :tree-props="{ children: 'list' }"
          default-expand-all
          class="elv-thirdparty-chartOfAccount-edit-table"
          header-cell-class-name="elv-thirdparty-chartOfAccount-edit-table-header__cell"
          header-row-class-name="elv-thirdparty-chartOfAccount-edit-table-header"
          row-class-name="elv-thirdparty-chartOfAccount-edit-table-row"
          cell-class-name="elv-thirdparty-chartOfAccount-edit-table-row__cell"
        >
          <el-table-column
            v-if="props.type === 'EXPORT'"
            width="156"
            class-name="elv-thirdparty-chartOfAccount-edit-table-row__accountId"
            label-class-name="elv-thirdparty-chartOfAccount-edit-table-row__accountId-label"
          >
            <template #header>
              <div />
              <span>{{ t('common.courseAccountId') }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.code }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="t('common.accountName')"
            :width="props.type === 'IMPORT' ? 406 : 250"
            class-name="elv-thirdparty-chartOfAccount-edit-table-row__accountName"
          >
            <template #default="{ row }">
              {{ row?.thirdpartyName ?? (locale === 'en' ? row.name : row?.nameCN || row.name) }}
            </template>
          </el-table-column>
          <el-table-column
            :width="props.type === 'IMPORT' ? 100 : 98"
            class-name="elv-thirdparty-chartOfAccount-edit-table-row__arrowRight"
          >
            <template #default="{ row }">
              <SvgIcon
                v-if="row.isLeaf || props.type === 'IMPORT'"
                name="arrow-right-line"
                width="16"
                height="16"
                fill="#838D95"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-if="props.type === 'EXPORT'"
            width="120"
            class-name="elv-thirdparty-chartOfAccount-edit-table-row__thridpartyCode"
          >
            <template #default="{ row }">
              <span class="elv-thirdparty-chartOfAccount-edit-table-row__thridpartyCode-text">
                {{ row.thirdpartyChartOfAccount?.code }}
              </span>
            </template>
          </el-table-column>
          <el-table-column class-name="elv-thirdparty-chartOfAccount-edit-table-row__thridpartyCodeSelect">
            <template #default="{ row }">
              <el-select
                v-if="row.isLeaf && props.type === 'EXPORT'"
                class="thirdparty-chartOfAccount-select"
                :model-value="row.thirdpartyChartOfAccount?.thirdpartyChartOfAccountId"
                :fit-input-width="true"
                clearable
                :placeholder="`${t('common.select')}...`"
                @change="(value: any) => onChangeMapping(row, value)"
              >
                <el-option
                  v-for="item in thirdpartyChartOfAccountList"
                  :key="item.thirdpartyChartOfAccountId"
                  :label="item.name"
                  :value="item.thirdpartyChartOfAccountId"
                />
              </el-select>

              <ChartOfAccountOverlayDropdown
                v-if="props.type === 'IMPORT'"
                v-model="row.chartOfAccountId"
                :clearable="false"
                :teleported="true"
                :placeholder="`${t('common.select')}...`"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="submitting"
        :disabled="saveDisabled"
        @click="onSaveChartOfAccountMapping"
        >{{ props.type === 'IMPORT' && props.model === 'add' ? t('common.next') : t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import {
  ChartOfAccountListType,
  ChartOfAccountExportThirdpartyType,
  ChartOfAccountImportMappingGroupType,
  ChartOfAccountImportMappingParamsType
} from '#/LedgerTypes'
import { useEntityStore } from '@/stores/modules/entity'
import ChartOfAccountOverlayDropdown from '../../../components/ChartOfAccountOverlayDropdown.vue'

const props = defineProps({
  importMappingData: {
    type: Object as () => ChartOfAccountImportMappingGroupType,
    required: true
  },
  model: {
    type: String,
    default: 'add' // add, edit
  },
  type: {
    type: String, // EXPORT | IMPORT
    required: true
  }
})

const route = useRoute()
const { t, locale } = useI18n()
const entityStore = useEntityStore()

const tableLoading = ref(true)
const tableHeight = ref(400)
const chartOfAccountList = ref<ChartOfAccountListType[]>([])
const thirdpartyChartOfAccountList = ref<ChartOfAccountExportThirdpartyType[]>([])
const chartOfAccountImportMapping = ref<ChartOfAccountImportMappingParamsType[]>([])
const showDialog = ref(false)
const submitting = ref(false)

const tableListRef = ref()

const emit = defineEmits(['onCloseDialog', 'onOpenAccountMappingNameDialog', 'onResetList'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const tableData = computed(() => {
  if (props.type === 'IMPORT') return chartOfAccountImportMapping.value
  const formatData = (data: any, parentAccountId: any) => {
    return data?.map((item: any) => {
      item.parentAccountId = parentAccountId
      if (item?.list) {
        formatData(item?.list, item.code)
      }
      return item
    })
  }
  return formatData(chartOfAccountList.value, null)
})

const saveDisabled = computed(() => {
  if (props.type === 'EXPORT') return false
  return chartOfAccountImportMapping.value.every((item) => item.chartOfAccountId === '')
})

/**
 * @description: 获取科目或账户表格数据
 */
const getChartOrAccountTableData = async () => {
  try {
    tableLoading.value = true
    if (props.type === 'EXPORT') {
      const [coaResult, thirdpartyCoaResult] = await Promise.all([
        LedgerApi.getChartOfAccountList(entityId.value, { thirdpartyPlatform: 'XERO' }),
        LedgerApi.getThirdpartyChartOfAccountList(entityId.value, 'XERO')
      ])
      chartOfAccountList.value = coaResult.data
      thirdpartyChartOfAccountList.value = thirdpartyCoaResult.data
    } else if (props.type === 'IMPORT' && props.model === 'add') {
      const result = await Promise.all([
        entityStore.fetchChartOfAccountList(entityId.value),
        LedgerApi.getChartOfAccountImportMappingPreview(entityId.value, {
          entityFileId: props.importMappingData.lastEntityFile.entityFileId
        })
      ])
      chartOfAccountImportMapping.value = result?.[1]?.data?.map((item) => {
        return {
          thirdpartyName: item,
          chartOfAccountId: ''
        }
      })
    } else if (props.type === 'IMPORT' && props.model === 'edit') {
      if (props?.importMappingData?.chartOfAccountImportMapping?.length) {
        await entityStore.fetchChartOfAccountList(entityId.value)
        chartOfAccountImportMapping.value = props.importMappingData.chartOfAccountImportMapping.map((item: any) => {
          return {
            thirdpartyName: item.thirdpartyName,
            chartOfAccountId: item.chartOfAccountId !== '0' ? item.chartOfAccountId || '' : ''
          }
        })
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/**
 * @description: 保存导出科目映射
 */
const onSaveExportChartOfAccountMapping = async () => {
  const relationships: any[] = []
  submitting.value = true
  function recurse(items: any) {
    items.forEach((item: any) => {
      if (item.thirdpartyChartOfAccount) {
        relationships.push({
          chartOfAccountId: item.chartOfAccountId,
          thirdpartyChartOfAccountCode: item.thirdpartyChartOfAccount.code
        })
      }
      if (item.list && item.list.length > 0) {
        recurse(item.list)
      }
    })
  }
  recurse(chartOfAccountList.value)
  try {
    await LedgerApi.editThirdpartyChartOfAccountMapping(entityId.value, 'XERO', relationships)
    ElMessage.success(t('message.saveSuccess'))
    showDialog.value = false
  } catch (error: any) {
    console.error(error)
    ElMessage.error(error.message)
  } finally {
    submitting.value = false
  }
}

/**
 * @description: 保存导入科目映射
 */
const onSaveImportChartOfAccountMapping = async () => {
  try {
    submitting.value = true
    if (props.model === 'add') {
      emit('onOpenAccountMappingNameDialog', chartOfAccountImportMapping.value)
      showDialog.value = false
    } else {
      await LedgerApi.editChartOfAccountImportMapping(
        entityId.value,
        props.importMappingData?.chartOfAccountImportMappingGroupId,
        {
          chartOfAccountImportMapping: chartOfAccountImportMapping.value
        }
      )
      ElMessage.success(t('message.saveSuccess'))
      showDialog.value = false
      emit('onResetList')
    }
  } catch (error: any) {
    console.error(error)
    ElMessage.error(error.message)
  } finally {
    submitting.value = false
  }
}

/**
 * @description: 保存科目映射
 */
const onSaveChartOfAccountMapping = async () => {
  if (props.type === 'EXPORT') {
    onSaveExportChartOfAccountMapping()
  } else {
    onSaveImportChartOfAccountMapping()
  }
}

/**
 * @description: 递归展开/折叠表格行
 * @param {*} arr
 * @param {boolean} isExpand
 */
const toggleRowExpansionForArray = (arr: any, isExpand: boolean) => {
  arr.forEach((i: any) => {
    tableListRef.value?.toggleRowExpansion(i, isExpand)
    if (i.list) {
      toggleRowExpansionForArray(i.list, isExpand)
    }
  })
}

/**
 * @description: 展开/折叠表格行
 * @param {string} status
 */
const onCheckTableExpand = (status: string) => {
  nextTick(() => {
    toggleRowExpansionForArray(tableData.value, status === 'EXPAND')
  })
}

/**
 * @description: 科目映射编辑
 * @param {*} row
 * @param {*} value
 */
const onChangeMapping = (row: any, value: any) => {
  const newValue = thirdpartyChartOfAccountList.value.find((item) => item.thirdpartyChartOfAccountId === value)
  const traverse = (obj: any) => {
    if (obj.chartOfAccountId === row.chartOfAccountId) {
      // eslint-disable-next-line no-param-reassign
      obj.thirdpartyChartOfAccount = newValue
    }
    if (obj.list && obj.list.length > 0) {
      obj.list.forEach(traverse)
    }
  }
  chartOfAccountList.value.forEach(traverse)
}

/**
 * @description: 切换弹窗显示状态
 */
const onCheckDialog = () => {
  showDialog.value = !showDialog.value
}

const onCloseDialog = () => {
  emit('onCloseDialog')
}

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value) {
      chartOfAccountImportMapping.value = []
      getChartOrAccountTableData()
    }
  }
)

defineExpose({ onCheckDialog, onCheckTableExpand, chartOfAccountImportMapping })
</script>

<style lang="scss">
.elv-account-mapping-edit-dialog {
  width: 960px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-mapping-edit-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 4px;
    width: 960px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-account-mapping-edit-content {
      width: 100%;
    }

    .elv-account-mapping-edit-content-head {
      .csv-file {
        display: flex;
        align-items: center;
        flex: 1 1;
        justify-content: center;

        svg {
          margin-right: 8px;
        }

        span {
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-thirdparty-chartOfAccount-edit-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d0d4d9;
    border-bottom-width: 1px;
    border-radius: 3px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-thirdparty-chartOfAccount-edit-table-header {
      background: #f9fafb;

      .elv-thirdparty-chartOfAccount-edit-table-header__cell {
        background: #f9fafb;
        height: 42px;
        box-sizing: border-box;
        border-right-color: transparent;
        border-bottom-color: #edf0f3;
        padding: 0px;

        &:last-child {
          border-right: 0px;
        }

        &.is-center {
          .cell {
            justify-content: center;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId-label {
          .cell {
            padding-left: 0px;
            height: 100%;

            > div {
              width: 40px;
              height: 100%;
              border-right: 1px solid #e4e7eb;
              margin-right: 10px;
            }
          }
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;
          color: #0e0f11;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .el-table__row.elv-thirdparty-chartOfAccount-edit-table-row {
      background-color: #fff;

      // &:last-of-type {
      //   .el-table__cell {
      //     border-bottom: 0;
      //   }
      // }
      .elv-thirdparty-chartOfAccount-edit-table-row__thridpartyCodeSelect {
        .cell {
          height: 100%;
          overflow: unset;
        }

        .el-select {
          height: 100%;
        }

        .el-select__wrapper {
          padding-right: 0;
          height: 100%;
        }

        .elv-journal-coa-dropdown-content {
          width: 100%;
          height: 100%;

          .el-select {
            height: 100%;
          }

          .el-select__wrapper {
            border: 0 !important;
            box-shadow: none !important;
            background-color: transparent;
            padding: 1px 0;

            .el-select__selected-item.el-select__placeholder {
              span {
                color: #2f63eb;
                font-family: 'Plus Jakarta Sans';
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 0;
              }

              &.is-transparent span {
                color: #aaafb6;
              }
            }

            .el-select__suffix {
              .el-icon.el-select__icon {
                color: #2f63eb;

                svg {
                  fill: #2f63eb;
                }
              }
            }
          }
        }
      }

      &.el-table__row--level-0 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        // &.el-table__row--level-0 .elv-thirdparty-chartOfAccount-edit-table-row__cell,
        // .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #f9fafb !important;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-size: 13px;
            font-weight: 700;
            word-break: break-word;
          }
        }

        span {
          padding-left: 10px;
        }
      }

      &.el-table__row--level-1 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #ffffff !important;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId span {
          padding-left: 30px;
        }
      }

      &.el-table__row--level-2 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #f9f9f9;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId span {
          padding-left: 50px;
        }
      }

      &.el-table__row--level-3 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #f9f9f9;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId span {
          padding-left: 70px;
        }
      }

      &.el-table__row--level-4 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #f9f9f9;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId span {
          padding-left: 90px;
        }
      }

      &.el-table__row--level-5 .elv-thirdparty-chartOfAccount-edit-table-row__cell {
        background-color: #f9f9f9;

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-thirdparty-chartOfAccount-edit-table-row__accountId span {
          padding-left: 110px;
        }
      }
    }

    .elv-thirdparty-chartOfAccount-edit-table-row__cell {
      padding: 0;
      height: 42px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__accountId {
        height: 42px;

        .cell {
          display: flex;
          padding-left: 0px;
          box-sizing: border-box;
          align-items: center;
          height: 100%;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .el-table__indent {
            padding-left: 0px !important;
            border-left: 0px;
          }

          .el-table__placeholder {
            width: 40px;
            box-sizing: border-box;
            height: 100%;
            padding-left: 40px !important;
            border-left: 0px;
          }

          span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #edf0f3;
            white-space: nowrap;
          }

          .el-table__expand-icon {
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            margin-right: 0px;
          }
        }
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__description {
        .cell {
          padding: 13px 10px;
        }
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__arrowRight {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__operating {
        .cell {
          display: flex;
          gap: 8px;

          svg {
            fill: #838d95;

            &.is-disabled {
              cursor: not-allowed !important;
            }

            &:not(.is-disabled):hover {
              cursor: pointer;
              fill: #1e2024;
            }
          }
        }
      }

      &.elv-thirdparty-chartOfAccount-edit-table-row__auxiliaryCode .cell {
        display: flex;

        .elv-thirdparty-chartOfAccount-edit-table-row__auxiliaryCode-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          margin-right: 4px;
          white-space: nowrap;

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;
      }

      .elv-thirdparty-chartOfAccount-edit-table-row__cell-activity {
        font-weight: 500;
      }

      &.el-table-column--selection {
        .cell {
          width: 100%;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .el-table__footer-wrapper {
      .el-table__footer {
        height: 42px;

        .cell.elv-thirdparty-chartOfAccount-edit-table-row__total {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .cell.elv-thirdparty-chartOfAccount-edit-table-row__amount {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;

          p {
            color: #838d95;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: none;
          }
        }
      }
    }
  }

  .elv-thirdparty-chartOfAccount-edit-table-row__thridpartyCode-text {
    font-weight: 500;
  }

  .elv-chartOfAccount-pagination__sizes-popper.el-popper {
    transform: translateY(10.5px);
  }

  .elv-account-mapping-edit-content {
    &-head {
      display: flex;
      align-items: center;
      height: 50px;

      .elven-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        img {
          width: 84px;
        }
      }

      .arrow-right-line {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 100px;

        img {
          width: 16px;
        }
      }

      .xero-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        img {
          width: 50px;
        }
      }
    }

    &-body {
      margin-top: 16px;
    }
  }

  .thirdparty-chartOfAccount-select {
    width: 100%;

    .el-select__wrapper {
      border: none;
      box-shadow: none;
      padding-left: 0;
    }

    .is-hovering {
      border: none !important;
      box-shadow: none !important;
    }

    .el-select__placeholder {
      color: #2f63eb;
    }

    .is-transparent {
      color: #aaafb6;
    }

    .el-select__suffix {
      .el-icon {
        color: #2f63eb;
      }
    }
  }
}
</style>
