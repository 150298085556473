<template>
  <el-dialog
    v-model="showDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-mapping-edit-name-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-mapping-edit-name-dialog-header__title">
        {{ props.model === 'add' ? t('title.saveAccountMappingName') : t('title.editAccountMappingName') }}
      </h4>
    </template>

    <div class="elv-account-mapping-content">
      <el-form ref="formRef" :model="accountMappingForm" label-position="top" :rules="rules" @submit.prevent>
        <el-form-item :label="t('common.name')">
          <el-input v-model="accountMappingForm.name" placeholder="Enter name" />
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :disabled="accountMappingForm.name === props.currentData?.name"
        :loading="saveLoading"
        @click="onSaveAccountMappingName"
        >{{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { trim, isEmpty } from 'lodash-es'
import type { FormInstance, FormRules } from 'element-plus'
import { ChartOfAccountImportMappingGroupType, ChartOfAccountImportMappingGroupParamsType } from '#/LedgerTypes'

const props = defineProps({
  currentData: {
    type: Object as () => ChartOfAccountImportMappingGroupType,
    required: true
  },
  model: {
    type: String,
    default: 'add' // add, edit
  },
  importMappingData: {
    type: Object as () => ChartOfAccountImportMappingGroupParamsType,
    required: true
  }
})

const route = useRoute()
const { t } = useI18n()

const showDialog = ref(false)
const formRef = ref<FormInstance>()
const saveLoading = ref(false)
const accountMappingForm = ref({
  name: ''
})

const rules = reactive<FormRules>({
  name: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
})

const emit = defineEmits(['resetList', 'onCloseDialog'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

/**
 * @description: 切换弹窗显示状态
 */
const onCheckDialog = () => {
  showDialog.value = !showDialog.value
}

const onCloseDialog = () => {
  accountMappingForm.value.name = ''
  emit('onCloseDialog')
}

/**
 * @description: 保存修改后的名称
 */
const onSaveAccountMappingName = async () => {
  if (!formRef.value) return
  await formRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        if (props.model === 'edit') {
          await LedgerApi.editChartOfAccountImportMapping(
            entityId.value,
            props.currentData?.chartOfAccountImportMappingGroupId,
            {
              name: trim(accountMappingForm.value.name)
            }
          )
        } else {
          await LedgerApi.createChartOfAccountImportMapping(entityId.value, {
            entityFileId: props.importMappingData?.entityFileId,
            chartOfAccountImportMapping: props.importMappingData?.chartOfAccountImportMapping,
            name: trim(accountMappingForm.value.name)
          })
        }
        ElMessage.success(t('message.saveSuccess'))
        emit('resetList')
        onCheckDialog()
      } catch (error: any) {
        console.error(error)
        ElMessage.error(error.message)
      } finally {
        saveLoading.value = false
      }
    }
  })
}

watch(
  [() => showDialog.value, () => props.currentData],
  () => {
    if (showDialog.value && !isEmpty(props.currentData)) {
      accountMappingForm.value.name = props.currentData.name
    }
  },
  { immediate: true }
)

defineExpose({ onCheckDialog })
</script>

<style lang="scss">
.elv-account-mapping-edit-name-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-mapping-edit-name-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 16px 24px 0px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-account-mapping-content {
      width: 100%;
    }
  }

  .el-dialog__footer {
    padding-top: 22px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
